<template>
  <div class="common-balances">
    <div class="table-wrapper">
      <rcc-table
        v-if="headerData.length > 0"
        :is-fixed-header="true"
        :page.sync="page"
        :page-count="pages"
        :items-per-page="50"
        :is-loading="isDataLoading"
        :headers="headerData"
        :rows="rows"
        @filters-changed="handleFiltersChanged"
      >
        <template #body>
          <tr
            v-for="(row, index) in rows"
            :key="index"
          >
            <td
              :class="['supplier', !row.isActive && 'supplier_inactive']"
            >
              {{ row.name }}
            </td>

            <td class="total-cell">
              {{ row.summary.total }}
              <div
                v-if="row.summary.updated_at"
                class="tooltip"
              >
                {{ $moment(row.summary.updated_at).format('DD.MM.YYYY HH:mm') }}
              </div>
            </td>

            <td
              v-for="({ value }) in headerData.slice(2)"
              :key="value"
              :class="getCellClasses(row.balances[value])"
            >
              <span v-if="row.balances[value]">
                {{ row.balances[value].balance || 0 }}
              </span>

              <span v-else>
                -
              </span>

              <div
                v-if="row.balances[value]"
                class="tooltip"
              >
                {{ getTooltipText(row.balances[value]) }}
              </div>
            </td>
          </tr>
        </template>
      </rcc-table>
    </div>
  </div>
</template>

<script>
import Page from '@/mixins/page'
import TableList from '@/mixins/table-list'

import RccTable from 'Components/table/rcc-table'

const INITIAL_HEADER_DATA = [
  {
    text: 'Поставщик',
    value: 'supplier',
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Поиск по поставщику:',
      options: []
    }
  },
  { text: 'Итого', value: 'summary' }
]

export default {
  name: 'RccCommonBalances',

  mixins: [Page, TableList],

  components: {
    RccTable
  },

  data() {
    return {
      headerData: [],
      rows: [],
      page: 1,
      pages: 1,
      isDataLoading: false
    }
  },

  mounted() {
    this.loadData()
  },

  watch: {
    page() {
      this.loadData()
    },

    headerData() {
      this.initFilters()
    }
  },

  methods: {
    loadData(_, params = {}) {
      this.isDataLoading = true

      this.$apiMethods.suppliers.balances({ page: this.page, ...(this.filtersQuery || {}), ...params })
        .then(({ items, page, pages }) => {
          this.headerData = [...INITIAL_HEADER_DATA]

          if (!pages) {
            this.pages = 1
            this.rows = []
            return
          }

          const headers = {}
          const rows = []

          for (const supplier of items) {
            const row = { name: supplier.name, isActive: supplier.is_active, balances: {}, summary: supplier.summary }

            for (const balance of supplier.balances) {
              headers[balance.dc_id] = balance.dc
              row.balances[balance.dc_id] = balance
            }

            rows.push(row)
          }

          for (let key in headers) {
            this.headerData.push({
              text: headers[key],
              value: key
            })
          }

          this.rows = rows
          this.page = page
          this.pages = pages
        })
        .finally(() => { this.isDataLoading = false })
    },

    getCellClasses(item) {
      return [
        'balance-cell',
        item && item.warning && 'balance-cell_warning',
        !item && 'balance-cell_gray'
      ]
    },

    getTooltipText(item = {}) {
      return item.updated_at ? this.$moment(item.updated_at).format('DD.MM.YYYY HH:mm') : 'Нет данных'
    },

    initFilters() {
      const index = this.headerData.findIndex(item => item.value === 'supplier')

      if (index < 0) {
        return
      }

      this.headerData[index].customFilter.name = 'supplier_ids'
      this.headerData[index].customFilter.callback = this.$apiMethods.suppliers.allList
    }
  }
}
</script>

<style lang="scss" scoped>
.common-balances {
  height: 100%;

  .table-wrapper {
    height: 100%;
  }

  th, td {
    min-width: 150px;
    padding: $data-table-header-and-footer-paddings !important;
    height: 30px !important;
    font-size: 13px !important;
  }

  th {
    background: $data-table-secondary-bg !important;
  }

  td {
    .tooltip {
      position: absolute;
      z-index: 100;
      background: #212121;
      opacity: 0;
      transition: opacity .3s ease;
      color: #ffffff;
      padding: 10px;
      border-radius: 5px;
      pointer-events: none;
      top: -120%;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;

      &:before {
        display: block;
        content: '';
        width: 10px;
        height: 10px;
        border: 10px solid transparent;
        border-top: 10px solid #212121;
        position: absolute;
        bottom: -16px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &:hover {
      .tooltip {
        transition: opacity .3s ease .5s;
        display: block;
        opacity: 1;
      }
    }
  }

  .supplier {
    &_inactive {
      background: $muted-gray;
    }
  }

  .total-cell {
    position: relative;
  }

  .balance-cell {
    position: relative;

    &_warning {
      background: $data-table-row-attention-bg;
    }

    &_gray {
      background: $muted-gray;
    }
  }

  tr {
    background: transparent;
    line-height: 1.3;

    &:hover {
      td {
        background: $data-table-hovered-bg;
      }
    }
  }

  ::v-deep tbody > tr > td:nth-child(-n + 2),
  ::v-deep thead > tr > th:nth-child(-n + 2) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 9998;
    background: white;
    background: #d1e3f3;
  }

  ::v-deep thead > tr > th:nth-child(-n + 2) {
    z-index: 9999;
  }

  ::v-deep thead > tr > th:nth-child(2),
  ::v-deep tbody > tr > td:nth-child(2) {
    left: 150px;
  }
}
</style>
